import Script from 'next/script'

// const InputCMS = ({ label, name, inputProps }: any) => {
//   return (
//     <div>
//       {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
//       <label>
//         {label && <span className="block">{label}</span>}
//         <Field
//           name={name}
//           className="border-b border-cloud  w-full focus:border-heliotrope bg-transparent text-white p-4"
//           {...inputProps}
//         />
//       </label>
//     </div>
//   )
// }

// const SelectCMS = ({ label, name, selectProps, options = [] }: any) => {
//   return (
//     <div>
//       {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
//       <label>
//         <span className="block">{label}</span>
//         <Field
//           name={name}
//           component="select"
//           className="appearance-none w-full border-b border-cloud focus:border-heliotrope bg-transparent text-white p-4"
//           {...selectProps}
//         >
//           <option value="">Séléctionnez une option</option>
//           {options.map((option: string, index: number) => (
//             <option value={option} key={index}>
//               {option}
//             </option>
//           ))}
//         </Field>
//       </label>
//     </div>
//   )
// }

// const TextareaCMS = ({ label, name, inputProps }: any) => {
//   return (
//     <div>
//       {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
//       <label>
//         <span className="block">{label}</span>
//         <Field
//           name={name}
//           component="textarea"
//           className="appearance-none w-full border-b border-cloud focus:border-heliotrope bg-transparent text-white p-4 h-20"
//           {...inputProps}
//         />
//       </label>
//     </div>
//   )
// }

export const FormCMS = () => {
  return (
    <>
      <div id="business-form" className="w-2/3 md:w-auto md:max-w-2xl m-auto" />
      <Script
        id="hs-2"
        src="https://js.hsforms.net/forms/embed/v2.js"
        onLoad={() => {
          window.hbspt.forms.create({
            formId: 'dfd8ad6e-1cb6-41e9-bd6f-df0456611cb7',
            region: 'na1',
            portalId: '2136919',
            target: '#business-form',
          })
        }}
      />
    </>
  )
}

export const FormOffersChristmasCMS = () => {
  return (
    <>
      <div id="christmas-form" className="w-2/3 md:w-auto md:max-w-2xl m-auto" />
      <Script
        id="hs-1"
        src="https://js.hsforms.net/forms/embed/v2.js"
        onLoad={() => {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '2136919',
            formId: '370c0bd9-0a3a-4d7a-89b4-1b9e1e60f2b5',
            target: '#christmas-form',
          })
        }}
      />
    </>
  )
}
