import { useMemo } from 'react'
import { findIndex } from 'lodash'
import classnames from 'classnames'
import { GetServerSideProps } from 'next'
import { useIntl } from 'react-intl'

import { Layout } from '../src/components/layout'
import { clientCMS } from '../src/utils/cms'
import { querySinglePage, querySinglePageHeaderAndFooter } from '../src/utils/queries'
import {
  ThemesListCms,
  FormatsBlockCms,
  SharedSectionCms,
  TextBlock,
  ImageFullWidth,
  ImageBanner,
  RichCta,
  ProductList,
  CatalogPrograms,
  HeroCms,
  NewsListCms,
  StatisticBlockCms,
  ProgramListCms,
} from '../src/components/cms'
import { GiftForm } from 'src/components/molecules/form/GiftForm'
import { FormCMS, FormOffersChristmasCMS } from 'src/components/cms/Form'
import { BadgeList } from 'src'

type SinglePageProps = {
  page: any
  preview: boolean
  headerAndFooter: any
  slug: string
}

export default function SinglePage({ page, preview, headerAndFooter, slug }: SinglePageProps) {
  const { formatMessage } = useIntl()
  const firstIsHeroBanner = useMemo(
    () => [0, 1].includes(findIndex(page.blocksCollection.items, { __typename: 'HeroBanner' })),
    [page.blocksCollection.items],
  )

  const header = headerAndFooter.blocksCollection.items.find(elem => elem.__typename === 'Header')
  const footer = headerAndFooter.blocksCollection.items.find(elem => elem.__typename === 'Footer')

  const isActivate = slug.includes('activate') || slug.includes('gift')
  const isBusiness = slug.includes('business')
  const isDownload = slug === 'download'
  const isOffersChristmas = slug.includes('offers/noel-pros')

  return (
    <Layout
      preview={preview}
      header={{
        logo: header?.logo,
        logoMobile: header?.logoMobile,
        headerLeft: header?.headerLeft,
        headerRight: header?.headerRight,
        displayAuthOrProfileButton: header?.displayAuthOrProfileButton,
      }}
      footer={{
        links: footer?.links,
        socials: footer?.socials,
        legals: footer?.legals,
      }}
    >
      <div
        className={classnames('space-y-20', {
          'pt-20': !firstIsHeroBanner,
        })}
      >
        {page.blocksCollection.items.map((slice: any, index: number) => {
          switch (slice.__typename) {
            case 'HeroBanner':
              return (
                <HeroCms
                  title={slice.title}
                  subtitle={slice.subtitle}
                  cta={slice.cta}
                  displayType={slice.displayType}
                  mobileImage={slice.mobileImage}
                  desktopImage={slice.desktopImage}
                  rating={slice.rating}
                  ctaLabel={slice.ctaLabel}
                  key={`section_${index}`}
                />
              )
            case 'MediasBlock':
              return (
                <NewsListCms
                  imageCollection={slice.imageCollection.items}
                  key={`section_${index}`}
                />
              )
            case 'GroupStatBlock':
              return (
                <StatisticBlockCms
                  statBlockCollection={slice.statBlockCollection}
                  key={`section_${index}`}
                />
              )
            case 'ProgramList':
              return (
                <ProgramListCms
                  displayType={slice.displayType}
                  title={slice.title}
                  description={slice.description}
                  cta={slice.cta}
                  programsCollection={slice.programsCollection}
                  key={`section_${index}`}
                />
              )

            case 'FormatsBlock':
              return (
                <FormatsBlockCms
                  title={slice.title}
                  description={slice.description}
                  formatsCollection={slice.formatsCollection}
                  key={`section_${index}`}
                />
              )
            case 'ThemesList':
              return (
                <ThemesListCms
                  title={slice.title}
                  description={slice.description.json}
                  themes={slice.themesCollection.items}
                  key={`section_${index}`}
                />
              )
            case 'ContentTypeRichText':
              return (
                <SharedSectionCms
                  title={slice.title}
                  subtitle={slice.subtitle}
                  description={slice.description}
                  image={slice.image}
                  cta={slice.cta}
                  displayType={slice.displayType}
                  imageDisplayType={slice.imageDisplayType}
                  key={`section_${index}`}
                />
              )
            case 'TextBlock':
              return (
                <TextBlock
                  title={slice.title}
                  description={slice.description}
                  alignment={slice.textAlignment}
                  key={`section_${index}`}
                />
              )

            case 'RichCta':
              return (
                <RichCta
                  title={slice.title}
                  subtitle={slice.subtitle}
                  name={slice.name}
                  cta={slice.cta}
                  key={`section_${index}`}
                />
              )

            case 'ImageFullWidth':
              return <ImageFullWidth image={slice.image} key={`section_${index}`} />

            case 'ImageBanner':
              return (
                <ImageBanner
                  title={slice.title}
                  imageDesktop={slice.imageDesktop}
                  imageMobile={slice.imageMobile}
                  key={`section_${index}`}
                />
              )

            case 'ProductList':
              return (
                <ProductList
                  productBlocksCollection={slice.productBlocksCollection.items}
                  key={`section_${index}`}
                />
              )

            case 'ProgramMosaic':
              return (
                <CatalogPrograms
                  title={slice.title}
                  description={slice.description}
                  programsCollection={slice.programsCollection}
                  cta={slice.cta}
                  key={`section_${index}`}
                />
              )

            default:
              return null
          }
        })}
      </div>
      {isDownload && <BadgeList />}
      {isActivate && <GiftForm couponId={page.couponId} />}
      {isBusiness && (
        <div id="contact" className="py-10 space-y-10">
          <h1 className="text-center">Contactez-nous</h1>
          <FormCMS />
        </div>
      )}
      {isOffersChristmas && (
        <div id="contact" className="py-10 space-y-10">
          <h1 className="text-center">{formatMessage({ id: 'common.cmsForm.title' })}</h1>
          <FormOffersChristmasCMS />
        </div>
      )}
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  params,
  preview = false,
  locale,
}) => {
  try {
    const singlePageSlug = (params?.slug as string[])?.join('/') || 'home'

    const clientRequest = clientCMS(preview)

    const [headerAndFooterData, singlePageData] = await Promise.all([
      clientRequest.query({
        query: querySinglePageHeaderAndFooter,
        variables: { preview, slug: singlePageSlug, locale },
      }),
      clientRequest.query({
        query: querySinglePage,
        variables: { preview, slug: singlePageSlug, locale },
      }),
    ])

    const headerAndFooter = headerAndFooterData.data.singlePageCollection.items[0]
    const pageData = singlePageData.data.singlePageCollection.items[0]

    if (!pageData) {
      return {
        notFound: true,
      }
    }

    return {
      props: {
        metas: {
          title: pageData.metaTitle,
          description: pageData.metaDescription,
        },
        page: pageData,
        headerAndFooter,
        preview,
        slug: singlePageSlug,
      },
    }
  } catch (error) {
    return {
      notFound: true,
    }
  }
}
