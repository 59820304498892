import { ErrorMessage, Field } from 'formik'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

type InputFieldProps = JSX.IntrinsicElements['input'] & {
  style?: 'underline' | 'rounded'
  label?: string
}

export const InputField = ({
  name,
  type = 'text',
  label = '',
  style = 'underline',
  placeholder = '',
  readOnly = false,
  required = false,
}: InputFieldProps) => {
  const { formatMessage } = useIntl()

  return (
    <label className="w-full block">
      {label && <span className="block text-stormyWheather text-xs uppercase">{label}</span>}
      <Field
        autoComplete="off"
        type={type}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        className={classnames(
          'border-solid w-full border-cloud focus:border-heliotrope outline-none',
          {
            'pt-2 pb-4 pr-4 border-b text-black': style === 'underline',
            'py-2 px-6 text-white border rounded-3xl bg-palombe': style === 'rounded',
          },
        )}
      />
      <ErrorMessage name={name}>
        {msg => (
          <div>
            <p className="text-watermelon text-xs pt-1 pl-6">{formatMessage({ id: msg })}</p>
          </div>
        )}
      </ErrorMessage>
    </label>
  )
}
