import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import Link from 'next/link'
import React from 'react'

import { InputField } from 'src/components/form/InputField'
import { Button } from 'src/components/atoms/button/Button'
import { getCheckCoupon } from 'src/resources'
import { useRouter } from 'next/router'

const CouponCheckSchema = Yup.object().shape({
  code: Yup.string().required('common.notifications.coupon.error.404'),
})

export const GiftForm = ({ couponId = null }) => {
  const { formatMessage: getI18nKey } = useIntl()
  const { push } = useRouter()

  const { mutateAsync, isLoading } = useMutation((code: string) => getCheckCoupon(code))

  const handleSubmit = async (values, { setErrors }) => {
    if (isLoading) return
    try {
      const data = await mutateAsync(values.code)

      if (couponId && data.coupon.id !== couponId) {
        return setErrors({
          code: `common.notifications.coupon.error.wrongPartner`,
        })
      }

      push(`${process.env.NEXT_PUBLIC_WEBAPP_URL}/auth/signup?coupon=${values.code}`)
    } catch (error) {
      setErrors({
        code: `common.notifications.coupon.error.${error?.response?.status ?? '500'}`,
      })
    }
  }

  return (
    <div className="md:w-1/2 lg:w-1/3 p-5 md:pl-12 lg:pl-24 space-y-8">
      <Formik
        initialValues={{ code: '' }}
        onSubmit={handleSubmit}
        validationSchema={CouponCheckSchema}
      >
        <Form className="flex md:flex-row flex-col items-start md:items-start gap-3 w-96">
          <InputField
            name="code"
            style="rounded"
            required
            placeholder={getI18nKey({
              id: 'common.form.field.placeholder.code',
            })}
          />
          <div className="w-36">
            <Button buttonType="primary" type="submit" disabled={isLoading}>
              {getI18nKey({ id: 'giftForm.button.submit' })}
            </Button>
          </div>
        </Form>
      </Formik>
      <div>
        <Link href={getI18nKey({ id: 'common.url.help' })}>
          <a className="underline" target="_blank">
            {getI18nKey({ id: 'gift.banner.links.help' })}
          </a>
        </Link>
      </div>
    </div>
  )
}
